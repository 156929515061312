<template>
  <div>
    <v-container>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            AREAS
          </h3>
        </v-card-title>
      </v-card><br>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="listModifyAreas"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              AREA
            </v-btn>
          </template>
          <v-card>
            <v-toolbar
              color="success"
              dark
              elevation="0"
            >{{ formTitle }}</v-toolbar><br>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.entity_id"
                      :items="listEntity"
                      label="Sucursal"
                      outlined
                      dense
                      item-text="name"
                      item-value="entity_id"
                      :error-messages="errors.get('entity_id')"
                      @input="errors.clear('entity_id')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Nombre"
                      outlined
                      dense
                      :error-messages="errors.get('name')"
                      @keydown="errors.clear('name')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.area_type_id"
                      :items="listType"
                      label="Tipo de area"
                      outlined
                      dense
                      clearable
                      item-text="name"
                      item-value="id"
                      :error-messages="errors.get('area_type_id')"
                      @input="errors.clear('area_type_id')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.parent.id"
                      :items="listAreas"
                      label="Depende de"
                      outlined
                      dense
                      clearable
                      item-text="name"
                      item-value="id"
                      :error-messages="errors.get('id')"
                      @keydown="errors.clear('id')"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success darken-1"
                text
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="success darken-1"
                text
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            clear-icon="mdi-delete"
            label="Buscar"
            class="mt-7"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-autocomplete
            v-model="filterArea"
            :items="listFilterArea"
            item-text="name"
            item-value="entity_id"
            outlined
            dense
            clearable
            label="Filtrar por endidad"
            class="mt-7"
            @input="fetchFiltersAreas(filterArea)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="areas"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
        :server-items-length="totalAreas"
        :options.sync="options"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-if="progress" v-slot:no-data>
          <div class="text-center">
            <v-progress-circular
              :size="40"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </div>

</template>
<script>
import axios from "axios";
import debounce from 'lodash/debounce';
import {Errors} from '../../plugins/errors'

export default {
  name: "Area",
  components: {},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      progress: false,
      areas: [],
      listAreas: [],
      listEntity: [],
      filterArea: null,
      listFilterArea: [],
      listType: [],
      errors: new Errors(),
      snackbar: false,
      filters: {
        search: ''
      },
      text: 'Operación realizada con éxito !!!',
      totalAreas: 0,
      options: {},
      headers: [
        { text: 'SUCURSAL',   value: 'entity.name' ,   sortable: false, class: "success white--text"},
        { text: 'AREA',       value: 'name',           sortable: false, class: "success white--text"},
        { text: 'TIPO',       value: 'area_type.name', sortable: false, class: "success white--text"},
        { text: 'DEPENDE DE', value: 'parent.name',    sortable: false, class: "success white--text"},
        { text: 'ACCIONES',   value: 'actions',        sortable: false, class: "success white--text"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        entity_id: null,
        name: '',
        area_type_id: '',
        parent: {
          id: null
        },
        id: null
      },
      defaultItem: {
        entity_id: null,
        name: '',
        area_type_id: '',
        parent: {
          id: null
        },
        id: null
      },
      parentModify: {
        id: null,
        name: 'NINGUNO'
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'REGISTRAR AREA' : 'EDITAR AREA'
    },
    search: {
      get () {
        return this.filters.search;
      },
      set (value) {
        this.filters.search = value;
        this.filterSearch()
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()

    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      async handler () {
        await this.fetchAreas();
      }
    },
  },
  created () {
    this.fetchAreas()
    this.listModifyAreas()
  },
  methods: {
    fetchAreas () {
      const params = { ...this.options, ...this.filters }
      let modifyList = []
      this.progress = true
      axios.get('/api/areas', {params})
          .then(response => {
            modifyList = response.data.data.data
            modifyList.filter((value) => {
              return value.parent == null ? value.parent = this.parentModify : value.parent
            })
            this.areas          = modifyList
            this.listType       = response.data.type
            this.totalAreas     = response.data.data.total
            this.listEntity     = response.data.entity
            this.listFilterArea = response.data.entity
            this.progress = false
          })
    },

    listModifyAreas () {
      axios.get('/api/list/areas')
          .then(response => {
            this.listAreas = response.data.area
          })
    },

    saveAreas () {
      const createArea = {
        'name'         : this.editedItem.name,
        'parent_id'    : this.editedItem.parent.id,
        'area_type_id' : this.editedItem.area_type_id,
        'entity_id'    : this.editedItem.entity_id,
      }

      axios.post('/api/areas', createArea)
          .then(() => {
            this.fetchAreas()
            this.close()
            this.snackbar = true;
          },error => this.errors.record(error.response.data.errors));
    },

    updateAreas () {
      const editArea = {
        'name'         : this.editedItem.name,
        'parent_id'    : this.editedItem.parent.id,
        'area_type_id' : this.editedItem.area_type_id,
        'entity_id'    : this.editedItem.entity_id,
        'id'           : this.editedItem.id
      }

      axios.put(`/api/areas/${this.editedItem.id}`, editArea)
          .then(() => {
            this.fetchAreas()
            this.close()
            this.snackbar = true;
          },error => this.errors.record(error.response.data.errors));
    },

    filterSearch: debounce(async function () {
      await this.fetchAreas();
    }, 1000),

    fetchFiltersAreas () {
      let modifyList = []
      if (this.filterArea === null) {
        this.fetchAreas()
      }else {
        axios.get(`/api/areas/filter/${this.filterArea}`)
            .then(response => {
              modifyList = response.data.data.data
              modifyList.filter((value) => {
                return value.parent == null ? value.parent = this.parentModify : value.parent
              })
              this.areas      = modifyList
              this.totalAreas = response.data.data.total
            })
      }
    },

    save () {
      if (this.editedIndex > -1) {
        this.updateAreas()
      } else {
        this.saveAreas()
      }
      this.errors.record('')
    },

    deleteAreas () {
      axios.delete(`/api/areas/${this.editedItem.area_id}`)
          .then(() => {
            this.fetchAreas()
            this.close()
            this.snackbar = true;
          })
    },

    editItem (item) {
      this.editedIndex = this.areas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.areas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteAreas()
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.defaultItem.parent.id = null
        this.editedIndex = -1
      })
      this.errors.record('')
      this.editedItem.parent.id = null
      Object.assign(this.$data, this.$options.data.bind(
          this.parentModify = {
            id: null,
            name: 'NINGUNO'
          }
      ))
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
